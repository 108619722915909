import { differenceInCalendarDays, differenceInDays, format, isTomorrow, parse } from 'date-fns';
import { get, isEmpty, isNil, omit, round, sumBy, gte } from 'lodash-es';
import { fromJS } from 'immutable';
import qs from 'qs';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en';

import { CLOUD_PRICING_DISCOUNT_NAMES, SYSCO_PAY_REDIRECTION_STATUS, SYSCO_QUANTITY_TYPES } from 'Framework/Shared';
import {
  currencyFormatter,
  decodeBase64,
  encodeBase64,
  isItemLocked,
  isSplitOnlyProduct,
  getSyscoPayBaseUrl,
} from 'Framework/SharedUtils';
import {
  context,
  ORDER_APPROVE_STATUS,
  ORDER_STATUS,
  SHIPPING_CONDITIONS,
  SYSCO_CATEGORIES,
  SYSCO_STOCK_TYPES,
} from 'Framework/Utils';
import {
  dateInOPCOTimeZone,
  DEFAULT_DELIVERY_DATE,
  getAdjustedPricingData,
  getDefaultOrderName,
  getDisplayPrice as getDisplayPriceFormatted,
  getIsDemoSession,
  getIsPricingV2Enabled,
  isCurrentUserInternal,
  isFeatureEnabled,
  isNotFullyAllocated,
  isPrintableText,
  ORDER_SOURCE_WEB,
  roundNumber,
  shouldShowManualSubsView,
  TEMP_ORDER_ID,
} from 'Modules/Shared';
import {
  OPTIMIZELY_FEATURE_ENTERPRISE_PROMO_CODES,
  OPTIMIZELY_FEATURE_ENABLE_PROMO_CODE_V3,
  OPTIMIZELY_FEATURE_FAVORITE_PRODUCTS_IN_ORDER,
  OPTIMIZELY_FEATURE_REMOVE_WILL_CALL_SHIPPING,
  OPTIMIZELY_FEATURE_SAVINGS_POTENTIAL,
  OPTIMIZELY_FEATURE_MIRAKL_INTEGRATION,
  OPTIMIZELY_FEATURE_CONFIRMATION_PAGE_PROMOTIONAL_CONTENT,
  OPTIMIZELY_FEATURE_FLAG_PIPIGNGNA_SOTF_ORDERING_FLOW,
  OPTIMIZELY_FEATURE_IFG_ORDERING,
  OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_CART_EXPORT,
  OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_CART_PRINT,
} from 'Framework/Constants';

import {
  ACCIDENTAL_ORDERING_CONFIGURABLE_THRESHOLD,
  ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY,
  ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY_WHEN_NO_WEEKLY_AVG,
  AVAILABLE_FOR_PICKUP_TEXT_CART,
  AVAILABLE_FOR_PICKUP_TEXT_CHECKOUT,
  AVAILABLE_FOR_PICKUP_TODAY_TEXT_CART,
  AVAILABLE_FOR_PICKUP_TOMORROW_TEXT_CART,
  CART_STAGE_V3_ITEM_GROUP_LEVEL_DEFAULT,
  CART_STAGE_V3_ITEM_GROUP_LEVEL_ERROR,
  CART_STAGE_V3_ITEM_GROUP_LEVEL_MARKETPLACE,
  CART_STAGE_V3_ITEM_GROUP_LEVEL_WARN,
  CURRENTLY_OUT_OF_STOCK,
  CURRENTLY_OUT_OF_STOCK_TEXT_CART,
  CURRENTLY_UNAVAILABLE,
  CURRENTLY_UNAVAILABLE_TEXT_CART,
  DAY_OF_WEEK_FORMAT_TOKEN,
  DEFAULT_PRICE,
  DEFAULT_PRICE_DECIMAL_PLACES,
  DELIVERS_AS_SOON_AS_TEXT_CART,
  DELIVERY_DATE_SHORT_FORMAT,
  DELIVERY_DATES_MAY_VARY,
  DELIVERY_DATES_MAY_VARY_TEXT_CART,
  DELIVERY_DATES_MAY_VARY_TEXT_CHECKOUT,
  ITEM_STATUS_DELIVERY_DATE_FORMAT,
  ITEM_STATUS_DELIVERY_DATE_FORMAT_FOR_CHECKOUT_PAGE,
  ITEM_STATUS_DELIVERY_DATE_FORMAT_FR,
  ITEM_STATUS_KEY_MAX_VALUE,
  ITEM_STATUS_KEY_MAX_VALUE_FOR_MARKETPLACE,
  NOT_AVAILABLE_FOR_PICKUP,
  NOT_AVAILABLE_FOR_PICKUP_TEXT_CART,
  NOT_AVAILABLE_FOR_PICKUP_TEXT_CHECKOUT,
  NOT_ELIGIBLE_FOR_NEXT_DAY_DELIVERY,
  NOT_ELIGIBLE_FOR_NEXT_DAY_DELIVERY_TEXT_CART,
  PICKUP_DATE_TEXT_CHECKOUT,
  PREFERRED_INDICATORS_ROW_HEIGHT,
  PRODUCTS_NOT_AVAILABLE,
  PRODUCTS_NOT_AVAILABLE_TEXT_CART,
  PromoCodeErrorType,
  PromoCodeStatus,
  SellerGroup,
  CheckoutItemsSection,
  NOT_AVAILABLE_FOR_PICKUP_CHECKOUT,
  ESTIMATED_TO_SHIP_TEXT_CART,
  ESTIMATED_TO_SHIP_CHECKOUT,
} from '../constants';
import messages from '../messages';
import { LanguageType } from '../../../schema/languageType';
import { STOCKED_STATUSES } from '../../components/AvailabilityIndicator/constants';
import { getSelectedLanguage, isItemDeletableOrderOrigin } from '../../../utils';
import {
  getIsProductShopOrderable,
  hideProduceOOSLabel,
  isPunchoutSession,
  isUnavailableDisruptedItem,
  ORDER_SOURCE_PUNCHOUT,
  isShowOmniOrderingEnabled,
} from '../../shared';

import { getIsForcedSub } from '../../shared/subsFlyout';
import { equalsIgnoreCase } from '../../shared/utils/stringUtils';
import { isLccCustomer, isShowThirdPartyDisclaimer } from '../../shared/utils';
import { isSellerInfoRenderingProductOutOfStock } from '../../commons/utils';
import {
  OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_ORDER_EXPORT,
  OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_ORDER_PRINT,
} from '../../../internal/constants';
import { getMarketplaceShippingDate } from '../../../utils/deliveryDateUtil';
import { isValidDeliveryDate } from './deliveryDateUtils';
import { extractDerivedProps, formatTotalQuantity } from './orderSummaryUtils';
import { hasLockedSubstitutes } from './substituteItemUtils';

export const getLocalizedDateFormat = () =>
  getSelectedLanguage() === LanguageType.FR_CA.code
    ? ITEM_STATUS_DELIVERY_DATE_FORMAT_FR
    : ITEM_STATUS_DELIVERY_DATE_FORMAT;

export const getLocale = () => (getSelectedLanguage() === LanguageType.FR_CA.code ? fr : en);

export const getIsManualSubstitutionEnabled = () => {
  const { customer: { autoSubstitution } } = context.instance.auth;
  return shouldShowManualSubsView() && autoSubstitution;
};

export const getIsHavingSubs = (item) => get(item, 'substituteItemSequence', []).length > 0;

const isCartStageProductDetailsEnabled = (item, isModificationInProgress) => {
  if (isItemLocked(item)) {
    return false;
  }
  if (isModificationInProgress) {
    return SYSCO_STOCK_TYPES.isStockTypeStocked(item.stockType) && item.isOrderable;
  }
  return item.isOrderable;
};

const isProductPageNavigationEnabled = (item, isModificationInProgress) => {
  if (isModificationInProgress) {
    return SYSCO_STOCK_TYPES.isStockTypeStocked(item.stockType) && item.isOrderable;
  }
  return true;
};

const getInitialRowHeight = (isCase, isSplittable) => {
  let rowHeight = 0;
  if (isCase && isSplittable) {
    rowHeight += 84;
  } else if (isCase || isSplittable) {
    rowHeight += 74;
  } else {
    rowHeight += 60;
  }
  return rowHeight;
};

/**
 * Decide if item fields in cart stage is editable.
 * @param {Object } item Line item.
 * @param {boolean} isSubsRelatedItem Item has subs or a sub itself.
 * @param {boolean} isModificationInProgress
 * @param {boolean} isCreditCardFlowForMA
 * @returns {boolean} If item is editable.
 */
export const isCartStageItemFieldEditable = (
  item,
  isSubsRelatedItem = false,
  isModificationInProgress = false,
  isCreditCardFlowForMA = false,
) => {
  if (isItemLocked(item)) {
    return false;
  }
  if (getIsDemoSession()) {
    return false;
  }
  if (isUnavailableDisruptedItem(item.isShopOrderable)) {
    return false;
  }
  if (isModificationInProgress) {
    return (
      SYSCO_STOCK_TYPES.isStockTypeStocked(item.stockType) &&
      !!item.isOrderable &&
      !isSubsRelatedItem &&
      !isCreditCardFlowForMA
    );
  }
  if (isSellerInfoRenderingProductOutOfStock(item)) {
    return false;
  }
  return !!item.isOrderable && !isSubsRelatedItem;
};

export const extractActiveOrderId = (activeOrderConfig, opCo, customerId) =>
  (activeOrderConfig && activeOrderConfig[`${opCo}|${customerId}`]) || null;

export const isViewingOrder = (location, orderId) => location.indexOf(`/order/${orderId}`) !== -1;

/**
 * Converts a datetime value in milliseconds (epoch) to the corresponding UTC date in MM/DD/YYYY format.
 * @param {number} dateTimeInMillis The number of milliseconds that has passed from 01/01/1970 (epoch format).
 * @param {string} dateFormat Format string.
 * @returns {string} The converted date in MM/DD/YYYY format or the provided dateFormat.
 */
export const dateInUTCFormat = (dateTimeInMillis, dateFormat = DELIVERY_DATE_SHORT_FORMAT) => {
  if (dateTimeInMillis && dateFormat) {
    const date = new Date(dateTimeInMillis);
    return format(`${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`, dateFormat, {
      locale: getLocale(),
    });
  }
  return '';
};

const isEALabelVisible = (isPricingDataModal, isExpandedAssortment) =>
  isPricingDataModal && isShowOmniOrderingEnabled() && isExpandedAssortment;

export const getRowHeight = (props, includeSubs = true, itemAsohData = null, isPricingDataModal = false) => {
  const {
    cs,
    ea,
    stockType,
    isPhasedOut,
    isLeavingSoon,
    isCase,
    isSplittable,
    substitutions,
    substituteItemSequence,
    gpo,
    isExpandedAssortment,
  } = props;
  const hasAllocatedCS = get(cs, 'isAllocated', false);
  const hasAllocatedEA = get(ea, 'isAllocated', false);
  const volumePricingTiers = get(cs, 'volumePricingTiers', []);
  const price = get(cs, 'unitPrice', null);

  const additionalInfoHeight = 12;

  const showWhenNotAllocated = !(hasAllocatedCS || hasAllocatedEA);
  const isLeavingSoonOrPhasedOut = isPhasedOut || isLeavingSoon;

  const showReplacement = showWhenNotAllocated && isLeavingSoonOrPhasedOut;
  const isBulkDiscountVisible = volumePricingTiers?.length > 0 && price;

  const availableStockStatus = itemAsohData ? get(itemAsohData, 'availableStockStatus') : null;
  const isInStockByVisible = STOCKED_STATUSES.isStockStatusInStockBy(availableStockStatus);
  const isOutOfStock = STOCKED_STATUSES.isStockStatusOutOfStock(availableStockStatus);

  let rowHeight = getInitialRowHeight(isCase, isSplittable);

  let additionalHeight = 0;

  if (showWhenNotAllocated && stockType) {
    additionalHeight += additionalInfoHeight;
  }

  additionalHeight = getAvailabilityIndicatorHeight(
    isInStockByVisible,
    isOutOfStock,
    additionalHeight,
    additionalInfoHeight,
  );

  if (hasAllocatedCS) {
    additionalHeight += additionalInfoHeight;
  }
  if (hasAllocatedEA) {
    additionalHeight += additionalInfoHeight;
  }
  if (showReplacement) {
    additionalHeight += additionalInfoHeight;
  }
  if (includeSubs && substituteItemSequence) {
    substituteItemSequence.forEach((substituteSupc) => {
      rowHeight += getRowHeight(substitutions[substituteSupc]) + 12;
    });
  }
  if (isBulkDiscountVisible) {
    additionalHeight += additionalInfoHeight;
  }
  if (!isEmpty(gpo)) {
    additionalHeight += PREFERRED_INDICATORS_ROW_HEIGHT;
  }

  if (isEALabelVisible(isPricingDataModal, isExpandedAssortment)) {
    additionalHeight += 16;
  }

  return rowHeight + additionalHeight;
};

export const getRowHeightV2 = (itemInfo, includeSubs = true, itemAsohData = null) => {
  const { cs, stockType, gpo, substituteItemSequence, substitutions, isOrderable, isPhasedOut } = itemInfo;

  const volumePricingTiers = get(cs, 'volumePricingTiers', []);
  const price = get(cs, 'unitPrice', null);

  const isBulkDiscountVisible = volumePricingTiers.length > 0 && price;

  const availableStockStatus = itemAsohData ? get(itemAsohData, 'availableStockStatus') : null;
  const isOutOfStock = STOCKED_STATUSES.isProductOutOfStock(availableStockStatus);

  let rowHeight = 136;

  if (stockType || isBulkDiscountVisible) {
    rowHeight += 17;
    if (stockType === SYSCO_STOCK_TYPES.NONSTOCK.displayName && isPhasedOut === true) {
      rowHeight += 16; // non-stock label and phased out label will be in two lines
    }
  }

  if (isOrderable === false) {
    rowHeight += 10;
  }

  if (isOutOfStock) {
    rowHeight += 17;
  }

  if (!isEmpty(gpo)) {
    rowHeight += PREFERRED_INDICATORS_ROW_HEIGHT;
  }

  if (includeSubs && substituteItemSequence) {
    substituteItemSequence.forEach((substituteSupc) => {
      rowHeight += getRowHeightV2(substitutions[substituteSupc]);
    });
  }

  return rowHeight;
};

const getAvailabilityIndicatorHeight = (isInStockByVisible, isOutOfStock, additionalHeight, additionalInfoHeight) => {
  if (isInStockByVisible || isOutOfStock) {
    return additionalHeight + additionalInfoHeight * 2;
  }
  return additionalHeight;
};

export const getPricingInfo = (volumePricingTiers, unitPrice, isCatchWeight) =>
  !isEmpty(volumePricingTiers)
    ? { volumePricingTiers: { cs: volumePricingTiers }, price: { cs: unitPrice }, perWeightFlag: isCatchWeight }
    : {};

export const getItemDetailsData = (
  itemData,
  itemAsohData,
  subsInfo = { isSubstitute: false, orderedSupc: '' },
  isModificationInProgress = false,
  hasSubs = false,
  itemInventoryPromiseData = null,
  isCartV2Enabled = false,
) =>
  (itemData && {
    id: itemData.supc,
    cs: {
      isAllocated: get(itemData, 'cs.isAllocated', false),
      allocated: get(itemData, 'cs.allocated', null),
      qtyAgainstAllocation: get(itemData, 'cs.qtyAgainstAllocation', null),
    },
    ea: {
      isAllocated: get(itemData, 'ea.isAllocated', false),
      allocated: get(itemData, 'ea.allocated', null),
      qtyAgainstAllocation: get(itemData, 'ea.qtyAgainstAllocation', null),
    },
    agreementIndicator: itemData.agreementIndicator,
    priceInfo: getPricingInfo(
      get(itemData, 'cs.volumePricingTiers', []),
      get(itemData, 'cs.unitPrice', null),
      get(itemData, 'isCatchWeight'),
    ),
    supc: itemData.supc,
    brand: itemData.brand,
    image: itemData.image,
    isPhasedOut: itemData.isPhasedOut,
    isLeavingSoon: itemData.isLeavingSoon,
    replacementMaterialId: itemData.replacementMaterialId,
    isSameDayDeliveryEligible: itemData.isSameDayDeliveryEligible,
    name: itemData.name,
    description: itemData.description,
    packSize: itemData.packSize,
    stockType: itemData.stockType,
    stockTypeCode: itemData.stockTypeCode,
    category: itemData.category,
    isShippingFeeApplicable: get(itemData, 'isShippingFeeApplicable'),
    isOrderable: itemData.isOrderable,
    isShopOrderable: getIsProductShopOrderable(itemData.isShopOrderable),
    isShowAgreementIndicator: itemData.agreementIndicator && !isEmpty(itemData.agreementIndicator.trim()),
    isDisabled: !isCartStageProductDetailsEnabled(itemData, isModificationInProgress),
    isProductNavigationDisabled: !isProductPageNavigationEnabled(itemData, isModificationInProgress),
    orderedProduct: subsInfo.orderedSupc, // for submitted orders
    isSubstitute: subsInfo.isSubstitute, // for submitted orders
    isFavorite: itemData.isFavorite, // for submitted orders
    isSubsExist: hasSubs, // for submitted orders
    isShowSubFlyOutLink: isModificationInProgress && getIsManualSubstitutionEnabled(), // for submitted orders
    showProgress: false,
    asohData: isCartV2Enabled ? itemAsohData : fromJS(itemAsohData), // Existing ProductInfo component expects an immutable object
    inventoryPromisesData: itemInventoryPromiseData,
    gpoData: get(itemData, 'gpo', []),
    isProductDetailNotAvailable: isProductDetailNotAvailable(itemData),
    isForcedSub: getIsForcedSub(itemData),
    isExpandedAssortment: itemData.isExpandedAssortment,
    supplyAvailability: itemData.supplyAvailability,
    sourceVendor: itemData?.sourceVendor || null,
    sellerId: itemData?.sellerId || null,
    sellerName: itemData?.sellerName || null,
    assortmentClassification: itemData?.assortmentClassification || null,
    rewardPoints: itemData?.rewardPoints || null,
    netWeight: itemData?.netWeight || null,
    sellerGroup: itemData?.sellerGroup || null,
    uuom: get(itemData, 'uuom', null),
  }) ||
  {};

// Note - isDisabled is set to false at this stage. But this is to be updated as per the requirements
export const getItemDeletionDataForSubs = (
  item,
  orderedSupc = null,
  isDisabled = false,
  isSubstitute = false,
  isDeletable = true,
) =>
  item
    ? {
        isDisabled,
        isDeletable,
        item: { supc: item.supc },
        substituteData: { isSubstitute, orderedSupc: orderedSupc || item.supc },
      }
    : {};

export const getItemDeletionData = (
  item,
  isModificationInProgress,
  isItemInSubs = false,
  isCreditCardFlowForMA = false,
  originatedOrderSource = '',
) => {
  if (item) {
    let isDisabled = false;

    if (isModificationInProgress) {
      // Any items that are having a stock type other than stocked will not be allowed to be deleted in modification state.
      // All non-orderable items can be deleted in modification state.
      isDisabled = !SYSCO_STOCK_TYPES.isStockTypeStocked(item.stockType) && item.isOrderable;
      // Consider subs and cc flow for MA
      isDisabled = isDisabled || isItemInSubs || isCreditCardFlowForMA;
    }
    if (getIsDemoSession() || isItemLocked(item) || hasLockedSubstitutes(item)) {
      isDisabled = true;
    }
    return { isDisabled, isDeletable: isItemDeletableOrderOrigin(originatedOrderSource), item: { supc: item.supc } };
  }
  return {};
};
export const getIsItemWillCallIneligible = (item) =>
  !(
    SYSCO_STOCK_TYPES.isStockTypeStocked(item.stockType) ||
    (SYSCO_STOCK_TYPES.isDemandStocked(item.stockType) && parseInt(item.demandStatus, 10) !== 3)
  );

export const getFormattedWillCallItemStatusDescription = (type, itemDeliveryDate) => {
  if (type === ITEM_STATUS_KEY_MAX_VALUE) {
    return `${AVAILABLE_FOR_PICKUP_TODAY_TEXT_CART} ${format(itemDeliveryDate, getLocalizedDateFormat())}`;
  } else if (type === ITEM_STATUS_KEY_MAX_VALUE - 1) {
    return `${AVAILABLE_FOR_PICKUP_TOMORROW_TEXT_CART} ${format(itemDeliveryDate, getLocalizedDateFormat())}`;
  }
  return `${AVAILABLE_FOR_PICKUP_TEXT_CART} ${format(itemDeliveryDate, DAY_OF_WEEK_FORMAT_TOKEN, {
    locale: getLocale(),
  })}, ${format(itemDeliveryDate, getLocalizedDateFormat())}`;
};

export const getItemStatusStockTypeMapping = (
  stockType,
  orderDeliveryDate,
  diffInDays,
  isWillCallOrder,
  isItemWillCallEligible,
) => {
  let itemStatusMapping = {
    deliveryDate: dateInOPCOTimeZone(orderDeliveryDate),
    type: ITEM_STATUS_KEY_MAX_VALUE - diffInDays,
  };
  const isItemDeliveredOnDeliveryDate = !isWillCallOrder && SYSCO_STOCK_TYPES.isStockTypeStocked(stockType);
  const isItemAvailableForPickupOnDeliveryDate = isWillCallOrder && isItemWillCallEligible;

  if (!isItemDeliveredOnDeliveryDate && !isItemAvailableForPickupOnDeliveryDate) {
    itemStatusMapping = isWillCallOrder ? NOT_AVAILABLE_FOR_PICKUP : DELIVERY_DATES_MAY_VARY;
  }
  return itemStatusMapping;
};

export const getItemStatusMarketplace = ({ cs, ea }, isWillCallOrder) => {
  let latestDeliveryDate = Date();

  if (isWillCallOrder) {
    return NOT_AVAILABLE_FOR_PICKUP;
  }

  if (get(cs, 'pricingExtn.latestDeliveryDate', null)) {
    latestDeliveryDate = parse(cs.pricingExtn.latestDeliveryDate, 'MM/DD/YYYY', new Date());
  } else if (get(ea, 'pricingExtn.latestDeliveryDate', null)) {
    latestDeliveryDate = parse(ea.pricingExtn.latestDeliveryDate, 'MM/DD/YYYY', new Date());
  } else {
    return false;
  }
  return {
    deliveryDate: latestDeliveryDate,
    type: ITEM_STATUS_KEY_MAX_VALUE_FOR_MARKETPLACE - differenceInCalendarDays(latestDeliveryDate, Date.now()),
  };
};

export const getLeadTimeToShipItemStatus = ({ cs, ea }, isWillCallOrder) => {
  let leadTimeToShipVal = 0;

  if (isWillCallOrder) {
    return NOT_AVAILABLE_FOR_PICKUP;
  }

  if (get(cs, 'pricingExtn.leadTimeToShip', null)) {
    leadTimeToShipVal = cs.pricingExtn.leadTimeToShip;
  } else if (get(ea, 'pricingExtn.leadTimeToShip', null)) {
    leadTimeToShipVal = ea.pricingExtn.leadTimeToShip;
  }
  return {
    leadTimeToShip: leadTimeToShipVal,
    type: ITEM_STATUS_KEY_MAX_VALUE_FOR_MARKETPLACE + leadTimeToShipVal,
  };
};

export const isOrderWithEAItemsScheduledForTomorrow = (
  isExpandedAssortmentItem,
  shouldShowExpandedAssortmentItemAfterCutoffGroup,
  orderDate,
) =>
  isExpandedAssortmentItem &&
  shouldShowExpandedAssortmentItemAfterCutoffGroup &&
  isTomorrow(dateInOPCOTimeZone(orderDate));

// checks whether a split price item is out of stock after submitting order (at order modify stage)
// a split priced product can be deemed out of stock if either cs/ es's allocated value is 0 and isAllocated flag is true
const isAllocatedOutOfStock = (item, isCs = true) => {
  const splitType = isCs ? 'cs' : 'ea';

  return get(item, `${splitType}.isAllocated`, false) && get(item, `${splitType}.allocated`, 0) === 0;
};

export const getItemStatus = (
  {
    item,
    dateRelatedInformation,
    shippingCondition,
    currentlyUnavailableItems = [],
    asohData = {},
    showExpandedAssortmentPostCutoffInfo = false,
  },
  includeItemAllocationForStockStatus = false,
) => {
  const { orderDeliveryDate, nextScheduledDeliveryDate, preferredDates } = dateRelatedInformation;
  const { supc, stockTypeCode, isExpandedAssortment } = item;
  const date = orderDeliveryDate || nextScheduledDeliveryDate;
  const isWillCallOrder = SHIPPING_CONDITIONS.isWillCall(shippingCondition);

  const asohOfItem = asohData[supc];
  const availableStockStatus = asohOfItem ? get(asohOfItem, 'availableStockStatus') : null;

  let itemStatus = isWillCallOrder ? NOT_AVAILABLE_FOR_PICKUP : DELIVERY_DATES_MAY_VARY;

  if (isMarketplaceItem(item)) {
    const result = getLeadTimeToShipItemStatus(item, isWillCallOrder);
    if (result) {
      return result;
    }
  }

  const isAllocatedQtyOutOfStock =
    includeItemAllocationForStockStatus && (isAllocatedOutOfStock(item) || isAllocatedOutOfStock(item, false));

  if (currentlyUnavailableItems.includes(supc)) {
    itemStatus = CURRENTLY_UNAVAILABLE;
  } else if (
    (isAllocatedQtyOutOfStock || getIsItemOutOfStock(availableStockStatus, stockTypeCode)) &&
    !hideProduceOOSLabel(item.category)
  ) {
    itemStatus = CURRENTLY_OUT_OF_STOCK;
  } else if (isProductDetailNotAvailable(item)) {
    itemStatus = PRODUCTS_NOT_AVAILABLE;
  } else if (isOrderWithEAItemsScheduledForTomorrow(isExpandedAssortment, showExpandedAssortmentPostCutoffInfo, date)) {
    itemStatus = NOT_ELIGIBLE_FOR_NEXT_DAY_DELIVERY;
  } else if (isValidDeliveryDate(date, preferredDates, shippingCondition, isExpandedAssortment)) {
    const { stockType } = item;
    const currentDateInOpco = dateInUTCFormat(Date.now());
    const diffInDays = differenceInDays(dateInOPCOTimeZone(date), currentDateInOpco);
    const isItemWillCallEligible = !getIsItemWillCallIneligible(item);
    itemStatus = getItemStatusStockTypeMapping(stockType, date, diffInDays, isWillCallOrder, isItemWillCallEligible);
  }

  return itemStatus;
};

/**
 * This function will format the item status data to show in the grid headers.
 */
export const getItemStatusForGrid = ({
  item,
  dateRelatedInformation,
  shippingCondition,
  currentlyUnavailableItems,
  asohData,
  showExpandedAssortmentPostCutoffInfo,
}) => {
  const { type, deliveryDate: itemDeliveryDate, leadTimeToShip: itemLeadTimeToShip } = getItemStatus(
    {
      item,
      dateRelatedInformation,
      shippingCondition,
      currentlyUnavailableItems,
      asohData,
      showExpandedAssortmentPostCutoffInfo,
    },
    true,
  );
  if (type === CURRENTLY_UNAVAILABLE.type) {
    return {
      type,
      description: CURRENTLY_UNAVAILABLE_TEXT_CART,
      level: CURRENTLY_UNAVAILABLE.level,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_ERROR,
    };
  }

  if (type === CURRENTLY_OUT_OF_STOCK.type) {
    return {
      type,
      description: CURRENTLY_OUT_OF_STOCK_TEXT_CART,
      level: CURRENTLY_OUT_OF_STOCK.level,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_ERROR,
    };
  }

  if (type === PRODUCTS_NOT_AVAILABLE.type) {
    return {
      type,
      description: PRODUCTS_NOT_AVAILABLE_TEXT_CART,
      level: PRODUCTS_NOT_AVAILABLE.level,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_ERROR,
    };
  }

  if (type === NOT_ELIGIBLE_FOR_NEXT_DAY_DELIVERY.type) {
    return {
      type,
      description: NOT_ELIGIBLE_FOR_NEXT_DAY_DELIVERY_TEXT_CART,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_WARN,
    };
  }

  if (type === DELIVERY_DATES_MAY_VARY.type) {
    return {
      type,
      description: DELIVERY_DATES_MAY_VARY_TEXT_CART,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_WARN,
    };
  }

  if (type === NOT_AVAILABLE_FOR_PICKUP.type) {
    return {
      type,
      description: NOT_AVAILABLE_FOR_PICKUP_TEXT_CART,
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_WARN,
    };
  }
  if (isMarketplaceItem(item)) {
    return getItemStatusDetailsForMarketplace(type, itemDeliveryDate, itemLeadTimeToShip);
  }

  if (SHIPPING_CONDITIONS.isWillCall(shippingCondition)) {
    return {
      type,
      description: getFormattedWillCallItemStatusDescription(type, itemDeliveryDate),
      ...CART_STAGE_V3_ITEM_GROUP_LEVEL_DEFAULT,
    };
  }

  return {
    type,
    description: `${DELIVERS_AS_SOON_AS_TEXT_CART} ${format(itemDeliveryDate, DAY_OF_WEEK_FORMAT_TOKEN, {
      locale: getLocale(),
    })}, ${format(itemDeliveryDate, getLocalizedDateFormat())}`,
    ...CART_STAGE_V3_ITEM_GROUP_LEVEL_DEFAULT,
  };
};

/**
 * This function will format the item status data to for marketplace items.
 */
export const getItemStatusDetailsForMarketplace = (type, itemDeliveryDate, leadTimeToShip) => ({
  type,
  description: `${ESTIMATED_TO_SHIP_TEXT_CART} ${getMarketplaceShippingDate(
    leadTimeToShip,
    DAY_OF_WEEK_FORMAT_TOKEN,
    getLocale(),
    getLocalizedDateFormat(),
  )}`,
  ...CART_STAGE_V3_ITEM_GROUP_LEVEL_MARKETPLACE,
});

/**
 * This function will check if item is marketplace or not.
 */
export const isMarketplaceItem = (item) =>
  isMiraklIntegrationEnabled() && item.sellerGroup === SellerGroup.MARKETPLACE.value;

/**
 * This function will check if item is sotf or not.
 */
export const isSotfItem = (item) =>
  isSpecialtyItemIntegrationEnabled() && item.sellerGroup === SellerGroup.SPECIALTY.value;

/**
 * This function will format the item status data to show in the checkout page item list.
 */
export const getItemStatusForCheckoutPage = (item, orderDeliveryDate, preferredDates, shippingCondition) => {
  const dateRelatedInformation = { orderDeliveryDate, nextScheduledDeliveryDate: null, preferredDates };
  const { type, deliveryDate: itemDeliveryDate, leadTimeToShip } = getItemStatus({
    item,
    dateRelatedInformation,
    shippingCondition,
  });

  let description;
  let itemsSection;

  if (isMarketplaceItem(item)) {
    const isWillCallOrder = SHIPPING_CONDITIONS.isWillCall(shippingCondition);
    const amendedType = isWillCallOrder ? NOT_AVAILABLE_FOR_PICKUP_CHECKOUT.type : type;
    const descriptionExtended = `${ESTIMATED_TO_SHIP_CHECKOUT} ${getMarketplaceShippingDate(
      leadTimeToShip,
      ITEM_STATUS_DELIVERY_DATE_FORMAT_FOR_CHECKOUT_PAGE,
      getLocale(),
    )}`;
    description = isWillCallOrder ? NOT_AVAILABLE_FOR_PICKUP_TEXT_CHECKOUT : descriptionExtended;
    itemsSection = CheckoutItemsSection.MARKETPLACE.value;

    return { type: amendedType, description, itemsSection };
  }

  if (type === DELIVERY_DATES_MAY_VARY.type) {
    description = DELIVERY_DATES_MAY_VARY_TEXT_CHECKOUT;
  } else if (type === NOT_AVAILABLE_FOR_PICKUP.type) {
    description = NOT_AVAILABLE_FOR_PICKUP_TEXT_CHECKOUT;
  } else {
    const formattedDeliveryDate = format(itemDeliveryDate, ITEM_STATUS_DELIVERY_DATE_FORMAT_FOR_CHECKOUT_PAGE, {
      locale: getLocale(),
    });
    if (SHIPPING_CONDITIONS.isWillCall(shippingCondition)) {
      description = `${AVAILABLE_FOR_PICKUP_TEXT_CHECKOUT} ${formattedDeliveryDate}`;
    } else {
      description = `${PICKUP_DATE_TEXT_CHECKOUT} ${formattedDeliveryDate}`;
    }
  }
  return { type, description };
};

/**
 * Return data for cart stage quantity template.
 * @param {Object} item Order item.
 * @param {boolean} isSubsRelatedItem Item has subs or a subs itself.
 * @param {boolean} isModificationInProgress
 * @param {boolean} isCreditCardFlowForMA
 * @param {string} originatedOrderSource Original order source.
 * @param {Object} asohOfItem  Asoh data of the order item.
 * @returns {{item: {Object}, hasMaxEaLimit: boolean, isDisabled: boolean}} Quantity template Data.
 */
export const getCartStageGridQuantityTemplateData = (
  item,
  isSubsRelatedItem = false,
  isModificationInProgress = false,
  isCreditCardFlowForMA = false,
  originatedOrderSource = '',
  asohOfItem = null,
) => ({
  hasMaxEaLimit: !isSplitOnlyProduct(item.isCase, item.isSplittable),
  isDisabled: !isCartStageItemFieldEditable(
    { ...item, asoh: asohOfItem },
    isSubsRelatedItem,
    isModificationInProgress,
    isCreditCardFlowForMA,
  ),
  item,
  originatedOrderSource,
});

export const isValidInputText = (text) => (text ? isPrintableText(text) : true);

/**
 * Generates the nature of errors required to display error messages in Cart stage.
 * @param {Array} itemSequence Sequence of the order items.
 * @param {Array} filteredData Items Filtered after the search text is applied.
 * @param {string} searchText The search text applied to filter items.
 * @returns {Object} An object containing the nature of errors isEmptyResultsForSearch: boolean, isEmptyOrder: Boolean.
 */
export const getCartStageErrors = (itemSequence, filteredData, searchText) => {
  const trimmedSearchText = searchText && searchText.trim();

  let isEmptyOrder = false;
  let isEmptyResultsForSearch = false;
  if (itemSequence.length === 0) {
    isEmptyOrder = true;
  } else if (filteredData.length === 0 && trimmedSearchText) {
    isEmptyResultsForSearch = true;
  }
  return {
    isEmptyOrder,
    isEmptyResultsForSearch,
  };
};

/**
 * Returns Sysco Category for given item.
 * @param {Object} item Sysco product.
 * */
export const getSyscoCategoryForItem = (item) => {
  if (item && item.category) {
    return SYSCO_CATEGORIES.getCategory(parseInt(item.category));
  }
  return { type: 0, description: '' };
};

const updatePriceAdjustmentDataForUoM = (item, uom, priceTemplateData) => {
  const modifiedPriceTemplateData = priceTemplateData;

  const qty = get(item, [uom, 'qty'], 0);
  const unitPrice = get(item, [uom, 'unitPrice'], null);
  const customerReferencePrice = get(item, [uom, 'customerReferencePrice'], 0);
  const discounts = get(item, [uom, 'discounts'], []);
  const volumePricingTiers = get(item, [uom, 'volumePricingTiers'], []);
  const exception = get(item, [uom, 'exception'], {});
  const agreements = get(item, [uom, 'agreements'], []);

  const shouldEnablePriceException =
    isCurrentUserInternal() && !priceTemplateData[uom].isDisabled && !getIsDemoSession();

  const { price, originalPrice, appliedDiscounts } = getAdjustedPricingData(qty, {
    price: unitPrice,
    customerReferencePrice,
    discounts,
    volumePricingTiers,
    exception,
    agreements,
  });

  if (SYSCO_QUANTITY_TYPES.isCS(uom)) {
    modifiedPriceTemplateData.isCaseNaN = isNaN(parseFloat(price));
    modifiedPriceTemplateData[uom].isPriceExceptionEnabled = shouldEnablePriceException;
  }
  if (SYSCO_QUANTITY_TYPES.isEA(uom)) {
    modifiedPriceTemplateData.isEachNaN = isNaN(parseFloat(price));
    const isCase = get(item, 'isCase', false);
    // show price exception only when it is not shown for CS
    modifiedPriceTemplateData[uom].isPriceExceptionEnabled =
      shouldEnablePriceException && !(isCase && !modifiedPriceTemplateData.isCaseNaN);
  }

  modifiedPriceTemplateData[uom] = { ...modifiedPriceTemplateData[uom], price, originalPrice, appliedDiscounts };

  return modifiedPriceTemplateData;
};

const updatePriceAdjustmentData = (item, priceTemplateData) => {
  let modifiedPriceTemplateData = updatePriceAdjustmentDataForUoM(
    item,
    SYSCO_QUANTITY_TYPES.CS.shortName,
    priceTemplateData,
  );

  modifiedPriceTemplateData = updatePriceAdjustmentDataForUoM(
    item,
    SYSCO_QUANTITY_TYPES.EA.shortName,
    modifiedPriceTemplateData,
  );

  return modifiedPriceTemplateData;
};

/**
 * Returns data required for the price template.
 * @param {Object} item An item in the order.
 * @param {boolean} isSubsRelatedItem Item has subs or a sub itself.
 * @param {boolean} isModificationInProgress
 * @returns {Object} The price template data corresponding to the item.
 */
export const getCartStageGridPriceTemplateData = (
  item,
  isSubsRelatedItem = false,
  isModificationInProgress = false,
) => {
  const isHandPricingAllowed = false;
  const csUnitPrice = get(item, 'cs.unitPrice');
  const eaUnitPrice = get(item, 'ea.unitPrice');
  const isDisabled = !isCartStageItemFieldEditable(item, isSubsRelatedItem, isModificationInProgress);
  const parsedCsUnitPrice = parseFloat(csUnitPrice);
  const parsedEaUnitPrice = parseFloat(eaUnitPrice);
  const isHandPriceableCS = get(item, 'cs.handPrice.handPricingAllowedFlag', false);
  const isHandPriceableEA = get(item, 'ea.handPrice.handPricingAllowedFlag', false);
  const commonAttributes = {
    productId: get(item, 'supc'),
    isCatchWeight: !!get(item, 'isCatchWeight'),
    averageWeightPerCase: get(item, 'averageWeightPerCase', 0),
    packSize: get(item, 'packSize', {}),
    weightUom: get(item, 'weightUom'),
    isDisabled,
  };
  const priceTemplateData = {
    isCase: !!get(item, 'isCase'),
    isCaseNaN: parsedCsUnitPrice === 0 || isNaN(parsedCsUnitPrice),
    isSplit: !!get(item, 'isSplittable'),
    isEachNaN: parsedEaUnitPrice === 0 || isNaN(parsedEaUnitPrice),
    cs: {
      basePrice: csUnitPrice || 0.0,
      pricingValues: get(item, 'cs.handPrice.pricingValues', {}),
      minHandlingPrice: get(item, 'cs.handPrice.handPriceHandlingValues.minHandlingPrice', {}),
      maxHandlingPrice: get(item, 'cs.handPrice.handPriceHandlingValues.maxHandlingPrice', {}),
      isHandPriceable: isHandPriceableCS,
      type: SYSCO_QUANTITY_TYPES.CS.shortName,
      isHandPricingAllowed,
      ...commonAttributes,
    },
    ea: {
      basePrice: eaUnitPrice || 0.0,
      pricingValues: get(item, 'ea.handPrice.pricingValues', {}),
      minHandlingPrice: get(item, 'ea.handPrice.handPriceHandlingValues.minHandlingPrice', {}),
      maxHandlingPrice: get(item, 'ea.handPrice.handPriceHandlingValues.maxHandlingPrice', {}),
      isHandPriceable: isHandPriceableEA,
      type: SYSCO_QUANTITY_TYPES.EA.shortName,
      isHandPricingAllowed,
      ...commonAttributes,
    },
  };

  if (getIsPricingV2Enabled()) {
    return updatePriceAdjustmentData(item, priceTemplateData);
  }

  return priceTemplateData;
};

/**
 * Returns data required for the total price template in CartStage V2.
 * @param {Object} item An item in the order.
 * @param {boolean} isSubsRelatedItem Item has subs or a sub itself.
 * @param {boolean} isModificationInProgress
 * @param {boolean} isCreditCardFlowForMA
 * @param {string} originatedOrderSource Original order source.
 * @returns {Object} The total price template data corresponding to the item in V2.
 */
export const getCartStageGridTotalTemplateDataV2 = (
  item,
  isSubsRelatedItem = false,
  isModificationInProgress = false,
) => {
  const unitPrices = getCartStageGridPriceTemplateData(item, isSubsRelatedItem, isModificationInProgress);

  let itemTotalPrice = 0;
  let itemTotalOriginalPrice = 0;
  const itemDiscounts = {};

  const getDiscountTypeByKey = (discounts, key) => {
    const discountName = CLOUD_PRICING_DISCOUNT_NAMES.getDiscountNameByKey(key);
    const matchingDiscount = discounts.find((discount) => equalsIgnoreCase(discountName, discount.name));
    return matchingDiscount?.type || '';
  };

  [SYSCO_QUANTITY_TYPES.CS.shortName, SYSCO_QUANTITY_TYPES.EA.shortName].forEach((type) => {
    if (type === SYSCO_QUANTITY_TYPES.CS.shortName && (!unitPrices.isCase || unitPrices.isCaseNaN)) return;
    if (type === SYSCO_QUANTITY_TYPES.EA.shortName && (!unitPrices.isSplit || unitPrices.isEachNaN)) return;
    itemTotalPrice += item[type].totalPrice || 0;
    itemTotalOriginalPrice += item[type].customerReferenceTotalPrice || 0;
    const itemDiscountsByType = item[type]?.discounts || [];
    if (item[type].discountsTotal) {
      Object.entries(item[type].discountsTotal).forEach(([name, priceAdjustment]) => {
        const priceAdjustmentValue = !itemDiscounts[name]
          ? priceAdjustment
          : itemDiscounts[name]?.priceAdjustment + priceAdjustment;
        itemDiscounts[name] = {
          priceAdjustment: priceAdjustmentValue,
          type: getDiscountTypeByKey(itemDiscountsByType, name),
        };
      });
    }
  });

  const itemDiscountsList = Object.entries(itemDiscounts).map(([key, value]) => ({
    name: CLOUD_PRICING_DISCOUNT_NAMES.getDiscountNameByKey(key),
    priceAdjustment: value.priceAdjustment,
    type: value.type,
  }));

  const isItemPriceMarket = (unitPrices.isCaseNaN && unitPrices.isEachNaN) || isProductDetailNotAvailable(item);

  return {
    totalPrice: itemTotalPrice,
    totalOriginalPrice: itemTotalOriginalPrice,
    discountsList: itemDiscountsList,
    isPriceMarket: isItemPriceMarket,
    isDisabled: !isCartStageItemFieldEditable(item, isSubsRelatedItem, isModificationInProgress),
    cartBrandConversionSavingData: {},
  };
};

/**
 * Get rounded display price.
 * @param {string| number} amount Number need to convert.
 * @param {number} decimalPlaces Decimal places (default is set to 2).
 * @returns {string} Formatted price.
 */
export const getDisplayPrice = (amount, decimalPlaces = DEFAULT_PRICE_DECIMAL_PLACES) =>
  currencyFormatter(roundNumber(amount || DEFAULT_PRICE, decimalPlaces));

export const getReadOrderTotal = (readOrderSummary) => {
  let totalPriceKey = 'totalPrice';
  if (readOrderSummary.isAllocated) {
    totalPriceKey = 'totalPriceAllocations';
  }
  return getDisplayPrice(get(readOrderSummary, totalPriceKey));
};

export const getReadOrderItemQuantities = (readOrderSummary) => {
  if (readOrderSummary.isAllocated) {
    return {
      totalCases: get(readOrderSummary.totalAllocatedQuantity, 'cs'),
      totalSplits: get(readOrderSummary.totalAllocatedQuantity, 'ea'),
    };
  }
  return {
    totalCases: get(readOrderSummary, 'totalCases'),
    totalSplits: get(readOrderSummary, 'totalSplits'),
  };
};

/**
 * Return data for cart stage total template.
 * @param {Object} item Order item.
 * @param {boolean} isSubsRelatedItem Item has subs or a sub itself.
 * @param {boolean} isSub If the item is a substitute.
 * @returns {{item: {Object}, isDisabled: boolean}} Total template Data.
 */
export const getCartStageGridTotalTemplateData = (item, isSubsRelatedItem = false, isSub = false) => ({
  isDisabled: !isCartStageItemFieldEditable(item, isSubsRelatedItem),
  isGrayedOut: isSub,
  isSubstitute: isSub,
  item: {
    cs: getDisplayPrice(get(item, 'cs.totalPrice')),
    ea: getDisplayPrice(get(item, 'ea.totalPrice')),
    isCase: !!item.isCase,
    isSplit: !!item.isSplittable,
  },
});

const getItemUOMTotal = (uom = {}, isSubstitute = false) => {
  const { isAllocated, totalPrice = 0, totalPriceAllocations = 0 } = uom;
  let price = 0;
  if (!isSubstitute && isAllocated) {
    // if the product is a original ordered and is allocated, consider price for allocations
    price = totalPriceAllocations;
  } else {
    // if the product is a substitute or an originally ordered item that is not yet allocated
    // consider total price
    price = totalPrice;
  }
  return round(price, DEFAULT_PRICE_DECIMAL_PLACES);
};

/**
 * Returns total item price data for the view stage.
 * @param item Order item.
 * @returns {{item: {total: string}}}
 */
export const getViewStageItemTotalTemplateData = (item, subsInfo = { isSubstitute: false, orderedSupc: '' }) => {
  const { cs, ea } = item;
  const totalPrice = getItemUOMTotal(cs, subsInfo.isSubstitute) + getItemUOMTotal(ea, subsInfo.isSubstitute);
  const { isNotAllocated } = getAllocationDetails(item, subsInfo.isSubstitute);

  return {
    totalPrice: getDisplayPrice(totalPrice),
    // should gray out the total price if item has subs or
    // total allocated item price is 0.
    grayOut: totalPrice === 0 || (!isNotAllocated ? getIsHavingSubs(item) : subsInfo.isSubstitute),
  };
};

/**
 * Returns of a given order ID is still the temporary ID assigned during dummy order creation.
 * @param orderId The order ID.
 * @return {boolean} Whether the order ID is temporary.
 */
export const isTempOrderId = (orderId) => orderId === TEMP_ORDER_ID;

/**
 * Transform an order modification payload to an order creation payload
 * Populates order name is not present.
 * @param {Object} orderUpdatePayload the order update payload
 * @returns {{orderItemSequence: *, order: {header: (*|{name: *}), items: *}}} the order creation payload
 */
export const transformPayloadsOrderUpdateToCreate = (orderUpdatePayload) => {
  const { modifiedOrder: { header, items }, orderItemSequence } = orderUpdatePayload;
  let { name } = header;

  if (!name || name.trim().length === 0) {
    name = getDefaultOrderName(new Date(), '');
  }

  return {
    order: {
      items,
      header: {
        ...header,
        name,
        originatedOrderSource: isPunchoutSession() ? ORDER_SOURCE_PUNCHOUT : ORDER_SOURCE_WEB,
      },
    },
    orderItemSequence,
  };
};

/**
 * Common attributes used in every order creation payload.
 * @type {{orderSource: string, shippingCondition: string, deliveryDate: null}}
 */
export const createOrderCommonPayload = (isInvoiceSeparate, shippingCondition = SHIPPING_CONDITIONS.GROUND.text) => ({
  invoiceSeparate: isInvoiceSeparate,
  deliveryDate: DEFAULT_DELIVERY_DATE,
  orderSource: ORDER_SOURCE_WEB,
  originatedOrderSource: isPunchoutSession() ? ORDER_SOURCE_PUNCHOUT : ORDER_SOURCE_WEB,
  shippingCondition,
});

/**
 * Provide the default shippingCondition based on customer deliveryMethods.
 * @param {Array} deliveryMethods Customer delivery methods.
 * @returns {string} Default shipping condition either GROUND or WILL_CALL.
 */
export const getDefaultShippingCondition = (deliveryMethods) => {
  if (deliveryMethods.length === 0 || deliveryMethods.includes(SHIPPING_CONDITIONS.GROUND.text)) {
    return SHIPPING_CONDITIONS.GROUND;
  }
  return SHIPPING_CONDITIONS.WILL_CALL;
};

/**
 * Generate line item specific information such as whether there is at least non orderable item among the items.
 * @param {Object} lineItems Line Items of the order.
 * @returns {{hasNonOrderableProduct: boolean, hasStockedItem: boolean, allItemsAreStocked: boolean}} An object containing line item specific info.
 */
export const lineItemSpecificInfo = (lineItems) => {
  let hasNonOrderableProduct = false,
    hasStockedItem = false,
    hasDemandItem = false,
    allItemsAreStocked = true,
    hasItemsOtherThanStocked = false,
    hasLockedItems = false,
    hasRemoteItem = false,
    hasSyscoRemoteItem = false,
    hasNSVItem = false;

  Object.entries(lineItems).forEach(([, lineItem]) => {
    const stockType = get(lineItem, 'stockType');
    const isItemStocked = SYSCO_STOCK_TYPES.isStockTypeStocked(stockType);
    const isDemandItem = SYSCO_STOCK_TYPES.isDemandStocked(stockType);
    const isRemoteItem = SYSCO_STOCK_TYPES.isStockTypeRemote(stockType);
    const isNSVItem = SYSCO_STOCK_TYPES.isStockTypeNonStocked(stockType);
    if (!hasNonOrderableProduct) hasNonOrderableProduct = get(lineItem, 'isOrderable') === false;
    if (!hasStockedItem) hasStockedItem = isItemStocked;
    allItemsAreStocked = allItemsAreStocked && isItemStocked;
    if (!hasItemsOtherThanStocked) hasItemsOtherThanStocked = !isItemStocked;
    if (!hasLockedItems) hasLockedItems = isItemLocked(lineItem);
    if (!hasDemandItem) hasDemandItem = isDemandItem;
    if (!hasRemoteItem) hasRemoteItem = isRemoteItem;
    if (!hasNSVItem) hasNSVItem = isNSVItem;
    if (!hasSyscoRemoteItem) {
      hasSyscoRemoteItem =
        isRemoteItem &&
        (get(lineItem, 'sellerGroup', '') !== SellerGroup.MARKETPLACE.value &&
          get(lineItem, 'sellerGroup', '') !== SellerGroup.SPECIALTY.value);
    }
  });

  return {
    hasNonOrderableProduct,
    hasStockedItem,
    hasDemandItem,
    hasRemoteItem,
    hasNSVItem,
    allItemsAreStocked,
    hasItemsOtherThanStocked,
    hasLockedItems,
    hasSyscoRemoteItem,
  };
};

export const getExtractedOrderDataImmutable = ({ order, isSubmittedOrderModification }, isReadOrder) =>
  fromJS(extractDerivedProps(order, isSubmittedOrderModification, isReadOrder));

/**
 * Generates the allocated quantity per UOM text content. Checks whether each UOM has full allocation or not, and sets
 * a value. Also sets a key for each UOM to be be used in the component to selectively switch color to the text.
 *
 * @param {Object} item - One line item.
 * @param {boolean} isSubstitute - If the item is a substitute or not.
 * @returns {Object} Information required to render the column.
 */
export const getAllocationDetails = (item, isSubstitute = false) => {
  let isCSNotFullyAllocated = false;
  let isEANotFullyAllocated = false;
  let caseAllocationText = '';
  let eachAllocationText = '';
  let isNotAllocated = false;

  const cs = get(item, 'cs', {});
  const ea = get(item, 'ea', {});

  const isCSAllocated = get(cs, 'isAllocated', false);
  const csAllocation = get(cs, 'allocated', 0);
  const csOrdered = get(cs, 'qtyAgainstAllocation', 0);

  const isEAAllocated = get(ea, 'isAllocated', false);
  const eaAllocation = get(ea, 'allocated', 0);
  const eaOrdered = get(ea, 'qtyAgainstAllocation', 0);

  if (isCSAllocated || (isSubstitute && csAllocation !== 0)) {
    isCSNotFullyAllocated = isNotFullyAllocated(csAllocation, csOrdered);
    caseAllocationText = `${csAllocation} ${SYSCO_QUANTITY_TYPES.CS.shortName.toUpperCase()}`;
  }

  if (isEAAllocated || (isSubstitute && eaAllocation !== 0)) {
    isEANotFullyAllocated = isNotFullyAllocated(eaAllocation, eaOrdered);
    eachAllocationText = `${eaAllocation} ${SYSCO_QUANTITY_TYPES.EA.shortName.toUpperCase()}`;
  }

  if (!isCSAllocated && !isEAAllocated && !isSubstitute) {
    isNotAllocated = true;
  }

  return {
    isCSNotFullyAllocated,
    isEANotFullyAllocated,
    caseAllocationText,
    eachAllocationText,
    isNotAllocated,
  };
};

const getUomPrice = (uomData, shouldAdjustPricingData = false) => {
  const itemHandPrice = get(uomData, ['handPrice', 'pricingValues', 'handPrice']);
  // if hand price is available, use hand price
  if (!isNil(itemHandPrice)) {
    return itemHandPrice;
  }
  // if price should get adjusted
  if (shouldAdjustPricingData) {
    const qty = get(uomData, 'qty', null);
    const adjustedPrices = getAdjustedPricingData(qty, uomData);
    // if there is adjustment return adjusted price or use unitPrice
    return adjustedPrices.price || get(uomData, 'unitPrice', null);
  }
  // if no hand price or adjustments to be done
  return get(uomData, 'unitPrice', null);
};

/**
 * Generates the quantity and the formatted price value for the given item. A flag is passed to determine the render
 * based on quantity ordered.
 *
 * @param {Object} item - One line item.
 * @param {string} uom - The unit of measurement (cs/ea).
 * @param {boolean} isSub - If the line item is a substituted product.
 * @param {boolean} shouldAdjustPricingData - Whether to adjust unit prices.
 * @returns {Object} Information required to render the column.
 */
export const getFormattedQuantityForItem = (item, uom, isSub = false, shouldAdjustPricingData = false) => {
  const isCatchWeight = get(item, 'isCatchWeight', false);
  const uomData = get(item, uom, {});
  const qtyKey = isSub ? 'allocated' : 'qty';
  const weightUom = get(item, 'weightUom', '');

  const unitPrice = getUomPrice(uomData, shouldAdjustPricingData);
  const quantity = get(uomData, qtyKey, null);
  const formattedPriceValue = getDisplayPriceFormatted(isCatchWeight, unitPrice);

  const parsedUnitPrice = parseFloat(unitPrice);
  const isPriceNaN = parsedUnitPrice === 0 || isNaN(parsedUnitPrice);
  const shouldShow = quantity > 0;

  return {
    quantity,
    uom,
    weightUom,
    formattedPriceValue,
    isCatchWeight,
    isPriceNaN,
    shouldShow,
  };
};

export const getFormattedItemStatusGroup = ({ type, description, totalQuantity, items, itemsSection }) => ({
  type,
  description,
  items,
  ...(isMiraklIntegrationEnabled() && { itemsSection }),
  summaryHeader: {
    totalItemCount: items.length,
    isSpecialDelivery: type === DELIVERY_DATES_MAY_VARY.type,
    totalQuantity: formatTotalQuantity(
      {
        totalCases: totalQuantity.cs,
        totalSplits: totalQuantity.ea,
      },
      true,
    ),
    totalUomQuantity: {
      cs: totalQuantity.cs,
      ea: totalQuantity.ea,
    },
  },
});

/**
 * Shop needs to extract some data about payment in the redirection from Sysco Pay to Cart Page.
 * The keys are defined as the contract with SyscoPay team.
 *
 * @param {string} searchQuery Search query from SyscoPay redirection URL.
 * @returns {{}|{code: string | null, cardType: string | null, shopToken: string | null, cardNumber: string | null, status: string | null, syscoPayId: string | null}}
 * Decoded query params.
 */
export const processPayToShopQueryParams = (searchQuery) => {
  const paymentData = qs.parse(searchQuery, { ignoreQueryPrefix: true });
  if (!isEmpty(paymentData)) {
    return {
      status: decodeBase64(get(paymentData, 'status')),
      syscoPayId: decodeBase64(get(paymentData, 'syscoPayId')),
      cardType: decodeBase64(get(paymentData, 'cardType')),
      cardNumber: decodeBase64(get(paymentData, 'cardNumber')),
      code: decodeBase64(get(paymentData, 'code')),
      shopToken: decodeBase64(get(paymentData, 'shopToken')),
    };
  }
  return {};
};

/**
 * Determines weather the page load occurred after a redirection from SyscoPay.
 * @param {boolean} isCreditCardFlow Is current flow is credit card.
 * @param {Object} location Location information of the current page.
 * @returns {boolean} Returns whether redirected from SyscoPay or not.
 * */
export const isRedirectedFromSyscoPay = (isCreditCardFlow, location) =>
  !!(isCreditCardFlow && location && !isEmpty(location.search));

/**
 * Extract credit card data from Sysco pay parameters.
 * @param {Object} payToSyscoParams Sysco pay parameters.
 * @returns {{code: string|null, success: boolean, cardType: string|null, cardNumber: string|null}} Credit card data.
 */
export const extractCreditCardData = (payToSyscoParams = {}) => ({
  cardNumber: payToSyscoParams.cardNumber,
  cardType: payToSyscoParams.cardType,
  code: payToSyscoParams.code,
  success: SYSCO_PAY_REDIRECTION_STATUS.isSuccessStatus(payToSyscoParams.status),
});

export const isPayParamsInitialized = (payToShopQueryParams) => !isNil(get(payToShopQueryParams, 'status'));

export const getIsRedirectingFromSyscoPay = (payToShopQueryParams, queryString) =>
  !isNil(get(payToShopQueryParams, 'status')) && !isNil(processPayToShopQueryParams(queryString).status);

/**
 * Append base64 encoded query params to sysco pay base url.
 * @param baseUrl
 * @param queryParams
 * @returns The url to redirect the user to.
 */
export const appendQueryParamsToPayBaseUrl = (queryParams) =>
  /* eslint-disable-next-line no-undef */
  `${getSyscoPayBaseUrl()}?${Object.keys(queryParams)
    .map((param) => `${param}=${encodeURIComponent(encodeBase64(queryParams[param]))}`)
    .join('&')}`;

export const generateModifyingOrderMetaData = (order) => {
  let activeOrderId = null;
  let isSubmittedOrderModification = true;
  if (
    ORDER_STATUS.isOpen(get(order, ['header', 'status'])) &&
    ORDER_APPROVE_STATUS.isOrderApprovalPending(get(order, ['header', 'lastReview', 'status']))
  ) {
    activeOrderId = get(order, ['header', 'id']);
    isSubmittedOrderModification = false;
  }
  return { activeOrderId, isSubmittedOrderModification };
};

/**
 * Extract meta information from computed row data.
 * @param {Object} rowData Computed row data.
 * @returns {{isSplit: boolean, isDisabled: boolean, isCase: boolean}} Meta information about item.
 */
export const getItemMetaInformation = (rowData) => {
  const { quantity: { isDisabled }, price: { isCase, isSplit } } = rowData;
  return {
    isDisabled,
    isCase,
    isSplit,
  };
};

/**
 * Extract meta information from computed row data.
 * @param {Object} rowData Computed row data.
 * @returns {{isSplit: boolean, isDisabled: boolean, isCase: boolean}} Meta information about item.
 */
export const getItemMetaInformationV2 = (rowData) => {
  const { productQuantityAndPrice: { quantity: { isDisabled }, price: { isCase, isSplit } } } = rowData;
  return {
    isDisabled,
    isCase,
    isSplit,
  };
};

/**
 * Returns a boolean based on the accidental ordering threshold and weekly average
 * quantity on ordered quantity.
 * @param {*} qtyCS CS quantity of the ordered item.
 * @param {*} qtyEA EA quantity of the ordered item.
 * @param {*} weeklyAvgQtyCS Average CS weekly purchases for the productId.
 * @param {*} weeklyAvgQtyEA Average EA weekly purchases for the productId.
 */
const isExeceedingMinimumOrderQtyThreshold = (qtyCS, qtyEA, weeklyAvgQtyCS, weeklyAvgQtyEA) =>
  (qtyCS > ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY &&
    weeklyAvgQtyCS > 0 &&
    qtyCS > weeklyAvgQtyCS * ACCIDENTAL_ORDERING_CONFIGURABLE_THRESHOLD) ||
  (qtyEA > ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY &&
    weeklyAvgQtyCS > 0 &&
    qtyEA > weeklyAvgQtyEA * ACCIDENTAL_ORDERING_CONFIGURABLE_THRESHOLD);

/**
 * Returns a boolean based on the accidental ordering quantity when no weekly average
 * quantity on ordered item quantity.
 * @param {*} qtyCS CS quantity of the ordered item.
 * @param {*} qtyEA EA quantity of the ordered item.
 * @param {*} weeklyAvgQtyCS Average CS weekly purchases for the productId.
 * @param {*} weeklyAvgQtyEA Average EA weekly purchases for the productId.
 */
const isExeceedingMinimumOrderQtyWhenNoWeeklyAvg = (qtyCS, qtyEA, weeklyAvgQtyCS, weeklyAvgQtyEA) =>
  (weeklyAvgQtyCS === 0 && qtyCS > ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY_WHEN_NO_WEEKLY_AVG) ||
  (weeklyAvgQtyEA === 0 && qtyEA > ACCIDENTAL_ORDERING_MINIMUM_ORDER_QTY_WHEN_NO_WEEKLY_AVG);

/**
 * Returns which items are exceeding the accidental ordering threshold or minimum
 * order quantity value considering weekly purchases
 * Minimum order qty to be considered = 5.
 * Minimum order qty to be considered when no weekly average = 10,
 * Default configurable threshold value = 2 (200%).
 * @param {*} orderItems Order items returned from selector.
 * @param {*} weeklyAveragePurchases Average weekly purchases for the product supc.
 */
export const getThresholdExceedingItemsUsingPurchaseHistoryV2 = (orderItems, weeklyAveragePurchases) => {
  const items = [];
  if (orderItems && orderItems.length > 0) {
    for (const orderItem of orderItems) {
      let weeklyAvgQtyCS = get(weeklyAveragePurchases, [orderItem.productDetails.supc, 'cs'], 0);
      let weeklyAvgQtyEA = get(weeklyAveragePurchases, [orderItem.productDetails.supc, 'ea'], 0);
      weeklyAvgQtyCS = isNil(weeklyAvgQtyCS) ? 0 : weeklyAvgQtyCS;
      weeklyAvgQtyEA = isNil(weeklyAvgQtyEA) ? 0 : weeklyAvgQtyEA;
      const qtyCS = get(orderItem, 'productQuantityAndPrice.quantity.item.cs.qty', 0);
      const qtyEA = get(orderItem, 'productQuantityAndPrice.quantity.item.ea.qty', 0);
      if (
        !get(orderItem, 'productQuantityAndPrice.quantity.isDisabled', false) &&
        (isExeceedingMinimumOrderQtyThreshold(qtyCS, qtyEA, weeklyAvgQtyCS, weeklyAvgQtyEA) ||
          isExeceedingMinimumOrderQtyWhenNoWeeklyAvg(qtyCS, qtyEA, weeklyAvgQtyCS, weeklyAvgQtyEA))
      ) {
        items.push(orderItem);
      }
    }
  }
  return items;
};

/**
 * Transforms product info to be used by DiscountPriceInfo component.
 * @param {*} product Product info object.
 */
export const getPriceInfo = (product) => ({
  volumePricingTiers: { cs: get(product, ['cs', 'volumePricingTiers'], []) },
  price: {
    cs: get(product, ['cs', 'unitPrice'], null),
    ea: get(product, ['ea', 'unitPrice'], null),
  },
});

/**
 * Calculates supc's weekly averaged purchase quantities.
 * @param {Object} aggregatedPurchaseDetails Purchase quantity details for the supc.
 * @param {number} numberOfWeeksToAvg Number of weeks to average, default 3.
 */
export const getWeeklyAveragedPurchasesForProduct = (aggregatedPurchaseDetails, numberOfWeeksToAvg = 3) => {
  const lastThreeWeekPurchaseAverage = {};

  if (!isNil(aggregatedPurchaseDetails)) {
    // pick last three weeks purchase details by removing current week
    const lastThreeWeekPurchaseDetails = omit(aggregatedPurchaseDetails, ['currentWeek']);
    // avg CS calculated by adding `quantity` from three weeks and dividing by 3, floor value considered
    lastThreeWeekPurchaseAverage.cs = Math.floor(
      sumBy(Object.values(lastThreeWeekPurchaseDetails), (obj) => obj.quantity) / numberOfWeeksToAvg,
    );
    // avg EA calculated by adding `split_quantity` from three weeks and dividing by 3, floor value considered
    lastThreeWeekPurchaseAverage.ea = Math.floor(
      sumBy(Object.values(lastThreeWeekPurchaseDetails), (obj) => obj.split_quantity) / numberOfWeeksToAvg,
    );
  }
  return lastThreeWeekPurchaseAverage;
};

/**
 * Get weekly averaged purchase map for supcs.
 * @param {Object} aggregatedPurchaseHistory - Aggregated purchase history from IPH service.
 * @returns {Object} Weekly purchases map.
 */
export const getWeeklyAveragedPurchasesMap = (aggregatedPurchaseHistory) => {
  const weeklyAveragedPurchasesMap = {};
  if (!isNil(aggregatedPurchaseHistory)) {
    Object.keys(aggregatedPurchaseHistory).forEach((supc) => {
      weeklyAveragedPurchasesMap[supc] = getWeeklyAveragedPurchasesForProduct(aggregatedPurchaseHistory[supc]);
    });
  }
  return weeklyAveragedPurchasesMap;
};

/**
 * Get delivery option number.
 * @param {string} shippingCondition - Shipping condition.
 * @returns {number} Delivery option number.
 */
export const getDeliveryOption = (shippingCondition) => {
  if (isPunchoutSession()) {
    return SHIPPING_CONDITIONS.GROUND.deliveryOption;
  }
  return SHIPPING_CONDITIONS.getShippingCondition(shippingCondition).deliveryOption;
};

export const getIsItemOutOfStock = (availableStockStatus, stockTypeCode) =>
  SYSCO_STOCK_TYPES.isStockTypeStockedFromCode(stockTypeCode) &&
  STOCKED_STATUSES.isProductOutOfStock(availableStockStatus);

export const isProductDetailNotAvailable = (item) => !!item.isProductDetailNotAvailable;

export const isPromoCodesEnabled = () => isFeatureEnabled(OPTIMIZELY_FEATURE_ENTERPRISE_PROMO_CODES);

export const isPromoCodeV3Enabled = () => isFeatureEnabled(OPTIMIZELY_FEATURE_ENABLE_PROMO_CODE_V3);

export const checkPromoCodeForValidationErrors = (promoCodeDetails, totalPrice) => {
  const promoCodeStatus = get(promoCodeDetails, 'status', PromoCodeStatus.UNKNOWN.value).toUpperCase();
  if (PromoCodeStatus.CONSUMED.value === promoCodeStatus) {
    return PromoCodeErrorType.ALREADY_CONSUMED_PROMO_CODE;
  }
  if (PromoCodeStatus.ACTIVE.value !== promoCodeStatus) {
    return PromoCodeErrorType.INVALID_PROMO_CODE;
  }
  if (totalPrice < get(promoCodeDetails, 'minimumBucketSize', 0)) {
    return PromoCodeErrorType.TOTAL_BELOW_MINIMUM_BUCKET_SIZE;
  }
  if (totalPrice < get(promoCodeDetails, 'reward', 0)) {
    return PromoCodeErrorType.TOTAL_BELOW_REWARD;
  }
  return null;
};

export const filterUniquePromoCodesEarliestExpiration = (promoCodeDetails) => {
  if (isNil(promoCodeDetails) && isEmpty(promoCodeDetails)) {
    return [];
  }

  if (!isPromoCodeV3Enabled()) {
    return promoCodeDetails;
  }

  const uniquePromoCodes = Object.values(
    promoCodeDetails.reduce((acc, promo) => {
      const { code, expiryDate } = promo;
      if (!acc[code] || expiryDate < acc[code].expiryDate) {
        acc[code] = promo;
      }
      return acc;
    }, {}),
  );

  return uniquePromoCodes.map((uniqueCode) => {
    const uniquePromoCodeDetails = promoCodeDetails.filter((promo) => promo.code === uniqueCode.code);
    const totalReward = sumBy(uniquePromoCodeDetails, 'reward');
    return {
      ...uniqueCode,
      name: uniquePromoCodeDetails.map((promo) => promo.name).join(','),
      ...(gte(totalReward, 0) && { reward: totalReward }),
    };
  });
};

export const getLockedItemModificationError = (orderedItem) => {
  if (!orderedItem || !get(orderedItem, 'isLocked', false)) return null;

  return {
    message: messages.lockedItemModificationError.defaultMessage,
    type: 'error',
    timeOut: 10,
    icon: 'error',
    closeOn: 'click',
  };
};

export const sortByDate = (key) => (a, b) => {
  if (a[key] && b[key]) {
    return a[key].localeCompare(b[key]);
  }
  return [];
};

export const shouldRemoveWillCallShipping = () => isFeatureEnabled(OPTIMIZELY_FEATURE_REMOVE_WILL_CALL_SHIPPING);

export const isFavoriteProductsInCartEnabled = () => isFeatureEnabled(OPTIMIZELY_FEATURE_FAVORITE_PRODUCTS_IN_ORDER);

export const isSpecialtyItemIntegrationEnabled = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_FLAG_PIPIGNGNA_SOTF_ORDERING_FLOW);

export const isMiraklIntegrationEnabled = () => isFeatureEnabled(OPTIMIZELY_FEATURE_MIRAKL_INTEGRATION);

export const isOptimizelyFlagCartExportEnabled = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_CART_EXPORT);

export const isOptimizelyFlagOrderExportEnabled = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_ORDER_EXPORT);

export const isOptimizelyFlagCartPrintEnabled = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_CART_PRINT);

export const isOptimizelyFlagOrderPrintEnabled = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_FLAG_MOTION_ENABLE_ORDER_PRINT);

export const showConfirmationPagePromotionalContent = () =>
  isFeatureEnabled(OPTIMIZELY_FEATURE_CONFIRMATION_PAGE_PROMOTIONAL_CONTENT);

export const getTotalRewardPoints = (items) =>
  items &&
  Object.values(items)
    .map((item) => item?.rewardPoints * item?.cs?.qty || 0)
    .reduce((acc, product) => acc + product, 0);

export const isSavingsPotentialFeatureEnabled = () => isFeatureEnabled(OPTIMIZELY_FEATURE_SAVINGS_POTENTIAL);

export const isShowForgottenItemsOnOrderDetailsPage = () => !isFeatureEnabled(OPTIMIZELY_FEATURE_IFG_ORDERING);

export const shouldShowLCC3rdPartyDisclaimer = () => isShowThirdPartyDisclaimer() && isLccCustomer();
